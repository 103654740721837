<template>
  <div class="main-content">
    <div class="flex">
      <div class="flex-1">
        <card>
          <template v-slot:header>
            <column-group>
              <div>
                <h3 class="text-3xl font-bold">Manage Taxonomy</h3>
              </div>
            </column-group>
          </template>
          <div class="flex flex-row" v-if="themes.length > 0">
            <div class="w-1/3">
              <column-group>
                <h3 class="pr-4 pl-4 pb-2.5 text-left text-2xl">Themes</h3>
                <div v-if="hasCreateTopicPermission">
                  <Badge color="orange" size="small" class="cursor-pointer" role="button" @click="toggleModal('theme')" :is-disabled="!userCanAddTopics">
                    Add Theme
                  </Badge>
                  <Badge color="orange" size="small" class="cursor-pointer" role="button" @click="toggleModal('category')" :is-disabled="!userCanAddTopics">
                    Add Category
                  </Badge>
                </div>
              </column-group>
              <div class="border border-gray-lightest py-2">
                <div v-for="(hItem, index) in themes" :key="hItem.theme.id" class="mx-2">
                  <column-group :cols="3" :class="[hItem.expanded ? 'bg-blue-light' : '', 'p-2']">
                    <div class="col-span-2">
                      <button class="w-10 float-left h-7" @click="themeSelected(index)" aria-label="Expand">
                        <Icon class="h-3 w-3"
                              :iconType="hItem.expanded ? 'minus' : 'plus'" />
                      </button>
                      <span @click="themeSelected(index)" role="button" class="cursor-pointer">{{ hItem.theme.name }} <badge color="white" size="small">{{ hItem.theme.id }}</badge></span>
                    </div>
                    <div class="text-right">
                      <btn class="w-min" color="blue-line" size="small" @click="toggleThemeEdit(hItem.theme)">Rename</btn>
                    </div>
                  </column-group>
                  <div v-if="hItem.expanded" :class="[hItem.expanded ? 'bg-gray-lightest' : '', 'pl-6 py-3 pr-3']">
                    <div v-for="(category, index) in hItem.categories" :key="category.id" class="py-1">
                      <column-group :cols="3">
                        <div class="col-span-2">
                          <span class="w-full text-left" @click="selectCategory(hItem.theme, category)" aria-label="Show Topics" role="button">
                            {{ category.name }} <badge color="gray" size="small">{{ category.id }}</badge>
                          </span>
                        </div>
                        <div class="text-right">
                          <btn class="w-min" color="blue-line" size="small" @click="toggleCategoryEdit(category)">Edit</btn>
                        </div>
                      </column-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-1/3">
              <Transition>
                <div v-if="loaded && selectedCategory != null && selectedCategoryTopics != null">
                  <h3 class="pr-4 pl-4 pb-2.5 text-left text-2xl">{{selectedCategory.name}}</h3>
                  <grid class="pl-4">
                    <template #gridHeaderCells>
                      <grid-header-cell @table-sorted="() => sort('sortByName')">Topics</grid-header-cell>
                      <grid-header-cell column-key="Details" :sortable="false">
                        <btn color="blue-line" size="small" v-if="hasCreateTopicPermission" @click="setEditingTopic()">Add Topic</btn>
                      </grid-header-cell>
                    </template>
                    <template #rows>
                      <template v-for="(dataRow, index) in selectedCategoryTopics" :key="dataRow.id">
                        <grid-row>
                          <grid-cell>{{ dataRow.name }} <badge color="white" size="small">{{ dataRow.id }}</badge></grid-cell>
                          <grid-cell>
                            <btn color="blue-line" size="small" @click="setEditingTopic(dataRow)">Edit</btn>
                          </grid-cell>
                        </grid-row>
                      </template>
                    </template>
                  </grid>
                </div>
              </Transition>
            </div>

            <div class="w-1/3">
              <Transition>
                <card v-if="editingTopic != null" class="ml-4 sticky top-0 overflow-auto" style="max-height: 635px;" :separateHeader="false">
                  <template v-slot:header>
                    <h3 class="text-left text-2xl">Edit Topic</h3>
                  </template>
                  <div class="mb-2">
                    <validated-vert-field-group label="Theme">
                      <dropdown name="theme"
                                :items="themeList"
                                v-model="selectedTheme.id" />
                    </validated-vert-field-group>
                  </div>
                  <div class="mb-2">
                    <validated-vert-field-group label="Category">
                      <dropdown name="category"
                                :fullWidth="true"
                                :items="categoryList"
                                v-model="selectedCategory.id" />
                    </validated-vert-field-group>
                  </div>
                  <div class="mb-2">
                    <validated-vert-field-group label="Company" v-if="selectedTheme.id == productThemeId">
                      <dropdown name="company"
                                :fullWidth="true"
                                :items="companyTopics"
                                v-model="editingTopic.company" />
                    </validated-vert-field-group>
                  </div>
                  <div class="mb-2">
                    <validated-vert-field-group label="URL" v-if="selectedTheme.id == companyThemeId | selectedTheme.id == productThemeId">
                      <text-field name="url"
                                  :fullWidth="true"
                                  v-model="editingTopic.url">
                      </text-field>
                    </validated-vert-field-group>

                    <FieldErrorMessage :field="v$.editingTopic.url"></FieldErrorMessage>
                  </div>
                  <div class="mb-2">
                    <validated-vert-field-group label="Topic Name">
                      <text-field name="TopicName"
                                  :fullWidth="true"
                                  v-model="editingTopic.name"
                                  @update:modelValue="onSearch">
                      </text-field>
                    </validated-vert-field-group>
                  </div>
                  <div class="mb-2">
                    <validated-vert-field-group label="Active Date">
                      <date-picker name="activeDate"
                                   v-model="editingTopic.activeDate">
                      </date-picker>
                    </validated-vert-field-group>
                  </div>
                  <div class="mb-2">
                    <validated-vert-field-group label="Description">
                      <textarea name="description"
                                class="flex input-field-container min-w-input border border-solid border-blue-gray rounded gap-1 h-12 pl-2 bg-white w-full"
                                v-model="editingTopic.description">
                    </textarea>
                    </validated-vert-field-group>
                  </div>
                  <div>
                    <btn @click="saveTopic" :disabled="topicSaving">{{saveTopicText}}</btn>
                    <btn color="blue-line" v-if="hasDeleteTopicPermission" @click="deleteTopic(editingTopic.id)">
                      Delete
                    </btn>
                  </div>
                  <Transition>
                    <alert v-if="saveSuccess" style-type="success" class="mt-4">
                      Saved successfully.
                    </alert>
                  </Transition>
                  <Transition>
                    <alert v-if="topicDeleteSuccess" style-type="success" class="mt-4">
                      Topic Deleted.
                    </alert>
                  </Transition>
                  <Transition>
                    <alert v-if="saveFailed" style-type="warning" class="mt-4">
                      {{errorMessage}}
                    </alert>
                  </Transition>
                  <Transition>
                    <alert v-if="topicDeleteFailed" style-type="warning" class="mt-4">
                      Topic could not be deleted. Please try again later.
                    </alert>
                  </Transition>
                </card>
              </Transition>
            </div>
          </div>
          <div v-if="!loaded" class="text-center">
            <div class="inline-block">
              <loading-spinner />
            </div>
          </div>
          <!-- display this message if there's no row data -->
          <div v-if="loaded && themes.length == 0" class="text-blue-darkest font-semibold text-center">There was an error and Topics were not loaded. Please refresh the page.</div>
        </card>
      </div>
    </div>

    <!--This modal is only for adding and editing themes and categories-->
    <Transition>
      <modal v-if="showAddModal" @close="closeModal">
        <form @submit.prevent="onModalSave">
          <div class="px-5 mb-4">
            <!-- Modal Header-->
            <div class="mb-5">
              <h1 class="text-3xl flex font-bold">{{editingParent.id != null ? "Edit" : "Add"}} {{modalType}}</h1>
            </div>
            <!-- User Information Text Inputs -->
            <div class="mb-4">
              <div class="mb-3">
                <validated-vert-field-group label="Theme" v-if="modalType == 'category'">
                  <dropdown name="Theme"
                            :fullWidth="true"
                            :items="themeList"
                            v-model="editingParent.theme"
                            class="mr-3" />

                </validated-vert-field-group>
              </div>
              <div>
                <validated-vert-field-group label="Name">
                  <text-field :placeholder="`${modalType} name`"
                              name="Name"
                              :fullWidth="true"
                              class="mr-3"
                              v-model="editingParent.name">
                  </text-field>
                </validated-vert-field-group>
              </div>
            </div>

            <div class="w-full left-0 bottom-0">
              <div class="my-2">
                <btn type="submit" color="orange">
                  {{saveModalText}}
                </btn>
                <btn color="blue-line" v-if="hasDeleteTopicPermission && editingParent.id != undefined" @click="onModalDelete(editingParent.id)">
                  Delete
                </btn>
                <Transition>
                  <alert v-if="modalSaveSuccess" style-type="success" class="mt-4">
                    Saved successfully.
                  </alert>
                </Transition>
                <Transition>
                  <alert v-if="modalSaveFailed" style-type="warning" class="mt-4">
                    {{errorMessage}}
                  </alert>
                </Transition>
                <Transition>
                  <alert v-if="modalDeleteSuccess" style-type="success" class="mt-4">
                    Topic Deleted.
                  </alert>
                </Transition>
                <Transition>
                  <alert v-if="modalDeleteFailed" style-type="warning" class="mt-4">
                    Topic could not be deleted. Please try again later.
                  </alert>
                </Transition>
              </div>
            </div>
          </div>
        </form>
      </modal>
    </Transition>
  </div>
</template>

<script>
  import {
    Alert,
    Btn,
    Badge,
    Card,
    ColumnGroup,
    DatePicker,
    Dropdown,
    Grid,
    GridCell,
    GridHeaderCell,
    GridPager,
    GridRow,
    LoadingSpinner,
    SpinnerIcon,
    TextField,
    Modal,
  } from "@bombora/component-library";
  import ValidatedVertFieldGroup from "@/components/shared/ValidatedVertFieldGroup.vue";

  import Icon from "@/components/shared/Icon.vue";
  import FieldErrorMessage from '@/components/shared/FieldErrorMessage.vue';

  import { useVuelidate } from '@vuelidate/core'
  import { helpers } from '@vuelidate/validators'
  import { validDomainRegex, cleanDomain } from "@/utils/domain";

  export default {
    name: "Taxonomy",
    components: {
      Alert,
      Btn,
      Badge,
      Card,
      ColumnGroup,
      DatePicker,
      Dropdown,
      FieldErrorMessage,
      Grid,
      GridCell,
      GridHeaderCell,
      GridPager,
      GridRow,
      Icon,
      LoadingSpinner,
      SpinnerIcon,
      TextField,
      ValidatedVertFieldGroup,
      Modal,
    },
    setup: () => ({ v$: useVuelidate() }),
    props: {},
    data: function () {
      return {
        themes: [],
        companyTopics: [],
        leafTopics: [],
        // For the dropdown
        themeList: [],
        categoryList: [],
        editingTopic: null,
        // Category/Theme
        editingParent: { theme: null, name: null },
        modalType: "Theme",
        selectedCategory: null,
        selectedTheme: null,
        selectedCategoryTopics: null,
        loaded: false,
        saveTopicText: "Save Topic",
        topicSaving: false,
        saveModalText: "Save",
        modalSaving: false,
        userFileDownloading: false,
        accountFileDownloading: false,
        hasCreateTopicPermission: true,
        hasDeleteTopicPermission: true,
        // Model is only for editing themes and categories
        showAddModal: false,
        // Magic id for Company Topics
        companyThemeId: '1500625',
        // Magic ID for Product Topics
        productThemeId: '1517120',
        saveFailed: false,
        modalSaveFailed: false,
        saveSuccess: false,
        modalSaveSuccess: false,
        errorMessage: 'Save failed. Please try again later.',
        modalDeleteSuccess: false,
        modalDeleteFailed: false,
        topicDeleteSuccess: false,
        topicDeleteFailed: false
      };
    },
    mounted() {
      this.loadAllTopics();
    },
    watch: {
    },
    validations() {
      return {
        editingTopic: {
          url: {
            required: helpers.withMessage('You must enter a valid URL', value => {
              // only validate if this is a company topic
              if (this.selectedTheme.id == this.companyThemeId) {
                // clean and validate it
                let cleaned = cleanDomain(value)
                return validDomainRegex.test(cleaned);
              }
              return true;
            })
          }
        }
      }
    },
    methods: {
      loadAllTopics() {
        // Transform the API response into a hierarchy
        this.$api.getTopicHierarchy()
          .then((res) => {
            this.themes = res.data.themes;
            this.themeList = this.themes.map(x => {
              return {
                value: x.id,
                label: x.name
              }
            });
            this.leafTopics = res.data.leafTopics;
            this.companyTopics = res.data.companyTopics.map(x => {
              return {
                value: x.id,
                label: x.name
              }
            });
            this.themeList = res.data.themes.map(x => { return { value: x.theme.id, label: x.theme.name } });
            this.loaded = true;
          })
      },
      userCanAddTopics() {
        this.$auth.userHasPermission("create:topic")
          .then(allowed => { this.hasCreateTopicPermission = allowed });
      },
      userCanAddTopics() {
        this.$auth.userHasPermission("delete:topic")
          .then(allowed => { this.hasDeleteTopicPermission = allowed });
      },
      toggleModal(modalType) {
        this.modalType = modalType;
        this.editingParent = { theme: null, name: null };
        this.showAddModal = true;
        this.saveModalText = `Save ${this.modalType}`;
      },
      toggleThemeEdit(theme) {
        this.modalType = "Theme";
        this.editingParent = theme;
        this.showAddModal = true;
        this.saveModalText = `Save ${this.modalType}`;
      },
      toggleCategoryEdit(category) {
        this.modalType = "Category";
        this.editingParent = category;
        this.showAddModal = true;
        this.saveModalText = `Save ${this.modalType}`;
      },
      closeModal() {
        this.showAddModal = false;
        this.editingParent = { theme: null, name: null };
      },
      themeSelected(rowIndex) {
        if (this.themes[rowIndex].expanded == undefined) {
          this.themes[rowIndex].expanded = true;
        } else {
          this.themes[rowIndex].expanded =
            !this.themes[rowIndex].expanded;
        }
        // Set the category list
        this.categoryList = this.themes[rowIndex].categories.map(x => {
          return {
            value: x.id,
            label: x.name
          }
        });
        // Clear the lists
        this.editingTopic = null;
        this.selectedCategory = null
        // Close all other detail rows.
        //this.themes.forEach((e, i) => {
        //  if (e.expanded != undefined && i != rowIndex) e.expanded = false;
        //});
      },
      selectCategory(theme, category) {
        this.selectedCategory = category;
        this.selectedTheme = theme;
        // Load topics from hierarchy for a category
        this.selectedCategoryTopics = this.leafTopics.filter(x => x.parentId == category.id) ?? [];
        this.selectedCategoryTopics.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        this.editingTopic = null;
      },
      async saveTopic() {
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) {
          return;
        }
        // This is a company topic
        if (this.selectedTheme.id == this.companyThemeId) {
           // Require URL
          if (this.editingTopic.url == '' || this.editingTopic.url == null) {
            this.saveFailed = true;
            this.errorMessage = "Company Topics require a URL";
            setTimeout(() => {
              this.saveFailed = false
              this.errorMessage = "Save failed. Please try again later."
            }, 5000);
            return;
          }
        }
        // This is a product topic
        if (this.selectedTheme.id == this.productThemeId) {
          // Require Company
          if (this.editingTopic.company == '' || this.editingTopic.company == null) {
            this.saveFailed = true;
            this.errorMessage = "Product Topics require a company";
            setTimeout(() => {
              this.saveFailed = false
              this.errorMessage = "Save failed. Please try again later."
            }, 5000);
            return;
          }
        }

        this.topicSaving = true;
        this.saveTopicText = "Saving...";
        // Call save topic
        try {
          if (this.editingTopic.id == 0 || this.editingTopic.id == undefined) {
            // Set parent and leaf
            this.editingTopic.isLeaf = true;
            this.editingTopic.parentId = this.selectedCategory.id;
          }
          let res = await this.$api.saveTopic(this.editingTopic);
          if (!res.success) {
            this.saveFailed = true;
            this.errorMessage = res.message;
            setTimeout(() => {
              this.saveFailed = false
              this.errorMessage = "Save failed. Please try again later."
            }, 5000);
          } else {
            this.loadAllTopics();
            this.saveSuccess = true;
            setTimeout(() => this.saveSuccess = false, 5000);
          }
        } catch (e) {
          this.saveFailed = true;
          setTimeout(() => this.saveFailed = false, 5000);
        } finally {
          this.topicSaving = false;
          this.saveTopicText = "Save Topic";
        }
      },
      async onModalSave(values) {
        this.modalSaving = true;
        this.saveModalText = "Saving...";
        // Call save category/theme
        if ((this.editingParent.id == 0 || this.editingParent.id == undefined) && this.modalType == 'category') {
          this.editingParent.parentId = this.editingParent.theme;
        }
        try {
          let res = await this.$api.saveTopic(this.editingParent);
          if (!res.success) {
            this.modalSaveFailed = true;
            this.errorMessage = res.message;
            setTimeout(() => {
              this.modalSaveFailed = false
              this.errorMessage = "Save failed. Please try again later."
            }, 5000);
          } else {
            this.loadAllTopics();
            this.modalSaveSuccess = true;
            setTimeout(() => {
              this.modalSaveSuccess = false;
              this.closeModal();
            }  , 5000);
          }
        } catch (e) {
          this.modalSaveFailed = true;
          setTimeout(() => this.modalSaveFailed = false, 5000);
        } finally {
          this.modalSaving = false;
          this.saveModalText = `Save ${this.modalType}`;
        }
      },
      async onModalDelete(id) {
        if (confirm("Are you sure you want to delete this topic?") == true) {
          try {
            let res = await this.$api.deleteTopic(id);
            if (!res) {
              this.modalDeleteFailed = true;
              setTimeout(() => this.modalDeleteFailed = false, 5000);
            } else {
              this.loadAllTopics();
              this.selectedCategory = null;
              this.modalDeleteSuccess = true;
              setTimeout(() => {
                this.modalDeleteSuccess = false;
                this.closeModal();
              }, 5000);
            }
          } catch (e) {
            this.modalDeleteFailed = true;
            setTimeout(() => this.modalDeleteFailed = false, 5000);
          }
        }
      },
      async deleteTopic(id) {
        if (confirm("Are you sure you want to delete this topic?") == true) {
          try {
            let res = await this.$api.deleteTopic(id);
            if (!res) {
              this.topicDeleteFailed = true;
              setTimeout(() => this.topicDeleteFailed = false, 5000);
            } else {
              this.loadAllTopics();
              this.selectedCategory = null;
              this.topicDeleteSuccess = true;
              setTimeout(() => {
                this.topicDeleteSuccess = false;
                this.editingTopic = null;
              }, 5000);
            }
          } catch (e) {
            this.topicDeleteFailed = true;
            setTimeout(() => this.topicDeleteFailed = false, 5000);
          }
        }
      },
      setEditingTopic(topic) {
        if (topic == undefined) {
          this.editingTopic = {};
        } else {
          this.editingTopic = topic;
        }
      }
    },
    computed: {
    }
  };
</script>

<style scoped></style>
