<template>
  <div class="main-content">
    <div class="flex">
      <div class="flex-1">
        <form  v-if="loaded" @submit.prevent="onSubmit">
          <card>
            <template v-slot:header>
              <column-group :cols="3">
                <div>
                  <h3 class="text-2xl font-bold">Create/Edit Content</h3>
                </div>
              </column-group>
            </template>
            <!-- fields -->
            <validated-field-group label="Date of Release:" required>
              <text-field v-model="release.releaseDate" name="release.releaseDate" placeholder="January 1, 1999" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.release.releaseDate" />
              </template>
            </validated-field-group>
            <validated-field-group label="Version Number:" required>
              <text-field v-model="release.version" name="release.version" placeholder="0.0.0" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.release.version" />
              </template>
            </validated-field-group>
            <validated-field-group label="Wave href:" required>
              <text-field class="w-30em" v-model="release.href" name="release.href" placeholder="http://bombora.com" />
              <template #validation-message>
                <FieldErrorMessage :field="v$.release.href" />
              </template>
            </validated-field-group>
            <p class="py-4 text-dark-gray tracking-wide italic">
              Accepts <b>HTML</b> and <b>inline CSS</b> styles. <a href="https://dirtymarkup.com" target="_blank" style="color: #00a0e2">dirtymarkup.com</a> can help you clean up and/or edit your code.
            </p>
            <div class="textarea-container">
              <textarea class="flex w-full min-w-input border border-solid border-blue-gray rounded gap-1 h-50 pl-2 bg-white" rows="10" v-model="release.content" name="release.content" placeholder="Release content..." />
              <div class="h-5 mb-3 text-right">
                <FieldErrorMessage :field="v$.release.content" />
              </div>
            </div>
            <template v-slot:footer>
              <div class="flex justify-between items-center mt-6">
                <!-- Left aligned buttons -->
                <div>
                  <btn type="button" color="blue" @click="triggerModal">Show Sample Code</btn>
                  <btn type="button" color="blue" @click="clearContent">Clear Content</btn>
                </div>
                <!-- Right aligned button -->
                <div>
                  <btn type="submit" color="orange">{{saving ? "Saving..." : "Save"}}</btn>
                </div>
              </div>
              <Transition>
                <alert v-if="saveSuccess" style-type="success" class="mt-4">
                  Your changes have been saved.
                </alert>
              </Transition>
              <Transition>
                <alert v-if="saveFailed" style-type="warning" class="mt-4">
                  There was a problem saving your changes. Please try again later.
                </alert>
              </Transition>
              <Transition>
                <alert v-if="formInvalid" style-type="warning" class="mt-4">
                  Please correct the errors in the form and resubmit.
                </alert>
              </Transition>
            </template>
          </card>
          <!-- Preview -->
          <div class="preview mt30">
            <h1 class="pagetitle">Preview</h1>
            <div class="releasenotes">
              <div class="mmwidth">
                <div class="wrapper cf">
                  <div class="cf">
                    <div class="rv-new">What's new</div>
                  </div>
                  <div class="rv-date">{{release.releaseDate}}</div>
                  <div class="rv-contents">
                    <div id="content" v-html="release.content"></div>
                  </div>
                  <a class="btn btn-xl btn-outline-blue" ng-href="{{rvUpdate.href}}">Read Our Latest Newsletter</a>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div v-if="!loaded" class="text-center">
          <div class="inline-block">
            <loading-spinner />
          </div>
        </div>
      </div>
    </div>
    <!-- Sample Code Modal -->
    <Transition>
      <modal v-if="showSampleModal" @close="handleClose">
        <div class="textarea-container pt-6">
          <textarea class="flex min-w-input border border-solid border-blue-gray rounded gap-1 h-50 pl-2 bg-white" rows="25" v-model="sampleContent" cols="80" placeholder="0">
          </textarea>
        </div>
      </modal>
    </Transition>
  </div>
</template>

<script>
  import { Alert, Btn, Card, TextField, TextArea, Modal, ColumnGroup, LoadingSpinner, SpinnerIcon } from "@bombora/component-library";
  import FieldErrorMessage from "@/components/shared/FieldErrorMessage";
  import ValidatedFieldGroup from "@/components/shared/ValidatedFieldGroup.vue";
  import { useVuelidate } from '@vuelidate/core'
  import { required, minValue, maxValue, helpers } from '@vuelidate/validators'

  export default {
    name: "Release",
    components: {
      Alert,
      Btn,
      TextField,
      Card,
      TextArea,
      Modal,
      ValidatedFieldGroup,
      FieldErrorMessage,
      LoadingSpinner,
      SpinnerIcon,
      ColumnGroup
    },
    setup: () => ({ v$: useVuelidate() }),
    data: function () {
      return {
        showSampleModal: false,

        release: {
          releaseDate: '',
          version: '',
          content: '',
          modifiedDate: '',
          href: ''
        },
        loaded: false,
        saving: false,
        saveSuccess: false,
        saveFailed: false,
        formInvalid: false,
        sampleContent: '<div>Company Surge<sup>®</sup> Reports</div> \n' +
          '<ul> \n' +
          '\t<li>Align your target account list with your "always-on" segment available  \n' +
          'exclusively through Eyeota. Create your segment by selecting Eyeota as your  \n' +
          'data exchange, uploading your target account list, and choosing your intent  \n' +
          'topics under the "Account-Based Marketing" filter. The segment can be used  \n' +
          'with Adform, AOL, AppNexus, Google Doubleclick, Ignition One, MediaMath,  \n' +
          'and Radium One.</li> \n' +
          '\t<li>Reporting</li> \n' +
          '</ul> \n' +
          ' \n' +
          '<div>Surge for Email</div> \n' +
          '<ul> \n' +
          '\t<li>More <b>Content!</b></li> \n' +
          '\t<li>More Insight!</li> \n' +
          '\t<li><a href="http://www.bombora.com/press/">Check This out!!</a></li> \n' +
          '</ul> \n' +
          '<div>Bombora Newsletter</div> \n' +
          '<ul> \n' +
          '\t<li>Keep informed of all our exciting new product updates and  \n' +
          'announcements. Check out the March issue of The Wave. Be sure to add  \n' +
          'marketing@bombora.com to your contact list so you don\'t miss out!</li>  \n' +
          '</ul>'
      };
    },
    validations() {
      return {
        release: {
          releaseDate: {
            required: helpers.withMessage('Date of Release is required.', required),
            dateFormat: helpers.withMessage('You have submitted an invalid date. Please use the format November 22, 2016.', value => {
              // Regular expression to match the format: Month Day, Year
              const regex = /^(January|February|March|April|May|June|July|August|September|October|November|December) \d{1,2}, \d{4}$/;

              // Check if the date string matches the required format
              if (!regex.test(value)) {
                return false;
              }

              // Parse the date string into a Date object
              const date = new Date(value);

              // Check if the Date object is valid (Invalid dates will be 'NaN')
              if (isNaN(date.getTime())) {
                return false;
              }

              // Check if the parsed date matches the original string to catch invalid dates like "February 30"
              const [month, day, year] = value.replace(',', '').split(' ');
              const formattedDate = date.toLocaleString('default', { month: 'long' }) + ' ' + date.getDate() + ', ' + date.getFullYear();

              return formattedDate === value;
            }),
          },
          version: {
            required: helpers.withMessage('Version Number is required.', required),
            versionFormat: helpers.withMessage('You have submitted an invalid version. Format must be 3 numbers separated by periods.', value => {
              const regex = /^\d+\.\d+\.\d+$/;
              return regex.test(value);
            }),
          },
          content: {
            required: helpers.withMessage('You have not submitted any content.', required)
          },
          href: {
            required: helpers.withMessage('Wave href is required.', required)
          }
        }
      }
    },
    mounted() {
      this.getReleaseData();
      this.loaded = true;
    },
    methods: {
      getReleaseData() {
        this.$api.getRelease()
          .then((res) => {
            this.release = res.data;
          })
      },
      async onSubmit() {
        this.releaseFormData = new FormData();
        this.releaseFormData.append('releaseDate', this.release.releaseDate)
        this.releaseFormData.append('version', this.release.version)
        this.releaseFormData.append('content', this.release.content)
        this.releaseFormData.append('modifiedDate', this.release.modifiedDate)
        this.releaseFormData.append('href', this.release.href)

        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) {
          this.formInvalid = true;
          setTimeout(() => this.formInvalid = false, 5000)
          return
        }
        else {
          this.saving = true;
          this.$api.submitRelease(this.releaseFormData)
            .then((res) => {
              this.saving = false
              this.saveSuccess = true
              setTimeout(() => this.saveSuccess = false, 5000)
            })
            .catch((err) => {
              this.saving = false
              this.saveFailed = true;
              setTimeout(() => this.saveFailed = false, 5000)
              console.error(err.message)
            })
        }
      },
      clearContent() {
        this.release.content = '';
      },
      triggerModal() {
        this.showSampleModal = true;
      },
      handleClose() {
        this.showSampleModal = false;
      }
    }
  }
</script>

<style>

  /*Styling is distinct for preview in this vue */
  h1.pagetitle {
    font-size: 37px;
    font-weight: 400;
    margin: 10px 0 0;
    padding: 0;
    text-align: left;
    color: #344852;
  }

  .preview .releasenotes .wrapper {
    border: 1px solid #dddde3;
  }

  .releasenotes .wrapper {
    padding: 30px 36px;
    max-width: 400px;
  }

  .rv-date {
    font-size: 20px;
    color: #00a0e2;
  }

  .releasenotes {
    color: #344852;
    font-size: 1em;
    line-height: 1.4;
    letter-spacing: .5px;
    box-sizing: border-box;
  }

  .rv-new {
    font-size: 37px;
    margin: 20px 0;
  }

  .rv-contents div {
    font-size: 20px;
    font-weight: 400;
    margin-top: 20px;
  }

  .rv-contents p {
    font-size: 14px;
    font-family: "Fira Sans", "sans-serif";
  }

  .rv-contents a {
    text-decoration: underline;
    color: #00a0e2;
    cursor: pointer;
  }

    .rv-contents a:hover {
      text-decoration: none;
    }

  .rv-contents ul,
  ul ul {
    list-style-type: circle;
    margin: 0;
    padding: 0;
    font-size: 0.83em;
  }

  .rv-contents li {
    list-style-type: none;
    font-size: 14px;
    color: #344852;
    margin: 0 0 5px 16px;
    position: relative;
  }

    .rv-contents li:before {
      position: absolute;
      left: -16px;
      top: 4px;
      color: #ff9517;
      font-size: 9px;
      margin: 0 16px 0 0;
      content: "";
    }

  .bora-circle-thin:before {
    content: "";
  }

  .fa,
  .setdrop .labels.selected:after {
    display: inline-block;
    font: normal normal normal 14px / 1 bombora;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .rv-contents b,
  strong,
  .b {
    font-weight: 500;
  }

  .rv-contents h5 {
    font-size: 0.83em;
    margin: 1.67em 0;
    font-weight: bold;
  }

  .btn {
    border: 1px solid transparent;
    border-radius: 100px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    height: 39px;
    line-height: 39px;
    padding: 0 6px;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
  }

  .btn-flex {
    width: 210px;
  }

  .btn-outline-blue {
    background-color: #fff;
    border-color: #00a0e2 !important;
    color: #00a0e2;
  }

  .btn-xl {
    width: 100%;
  }

  .releasenotes .btn {
    margin-top: 20px;
  }

  /* for admin release notes editor */
  .preview .releasenotes .wrapper {
    border: 1px solid #dddde3;
  }
</style>
