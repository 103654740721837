<template>
  <header class="main-content flex items-center">
  <router-link class="w-10 pr-4" to="/">
    <img src="@/assets/b-White.png" alt="Bombora" />
  </router-link>
  <nav class="main-content flex items-center mx-1">
    <div class="flex-1">
      <button :class="['nav-btn', routePath.startsWith('/account') ? 'nav-btn-active': '']">
        <router-link to="/account/list">Accounts</router-link>
      </button>
      <button :class="['nav-btn', routePath.startsWith('/release') ? 'nav-btn-active': '']">
        <router-link to="/release">Release</router-link>
      </button>
      <button :class="['nav-btn', routeName == 'taxonomy' ? 'nav-btn-active': '']">
    <router-link to="/taxonomy">Taxomony</router-link>
  </button>
      <button :class="['nav-btn', routeName == 'domainappend' ? 'nav-btn-active': '']">
        <router-link to="/domainappend">Domain Append</router-link>
      </button>
      <button :class="['nav-btn', routeName == 'keywordtotopic' ? 'nav-btn-active': '']">
        <router-link to="/keywordtotopic">Keyword To Topic</router-link>
      </button>

      <!--TODO Uncomment as pages are built-->
      <!--<button :class="['nav-btn', active == 'd' ? 'nav-btn-active': '']">
    <router-link to="/account/list">Domains</router-link>
  </button>
  <button :class="['nav-btn', active == 'r' ? 'nav-btn-active': '']">
    <router-link to="/account/list">Release</router-link>
  </button>
  <button :class="['nav-btn', active == 's' ? 'nav-btn-active': '']">
    <router-link to="/account/list">Shipper</router-link>
  </button>
  <button :class="['nav-btn', active == 'kt' ? 'nav-btn-active': '']">
    <router-link to="/account/list">Keyword to Topic</router-link>
  </button>-->
    </div>
  </nav>
    <div class="flex-1 text-right">
      <div v-if="!$auth.loading.value">
        <button v-if="!$auth.isAuthenticated.value" @click="login" class="text-white focus:outline-none">
          Login
        </button>
        <button v-if="$auth.isAuthenticated.value" @click="logout" class="text-white focus:outline-none">
          Logout
        </button>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "TopNav",
    computed: {
      routeName() {
        return this.$route.name.toLowerCase();
      },
      routePath() {
        return this.$route.path.toLowerCase();
      }
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect()
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    }
  }
}
</script>

<style lang="css" scoped>
  .nav-btn {
    @apply focus:outline-none  my-0 mx-5 text-gray-light;
  }
    .nav-btn > a {
      @apply py-2;
    }

    .nav-btn:first-child, .nav-btn:first-child:hover {
      @apply ml-1;
    }
    .nav-btn:hover,
    .nav-btn-active {
      @apply border-b-4 border-white;
      @apply focus:outline-none py-2 my-0 mx-5 text-white;

    }
</style>
