import accountActions from './accountActions';
import userActions from './userActions';
import KeywordTopicActions from "@/store/keywordTopicActions";
import topicActions from './topicActions';
import domainAppendActions from "@/store/domainAppendActions";
import releaseActions from "@/store/releaseActions";

export default api => {
    return {
        // Account Controller Actions
        ...accountActions(api),
        // User Controller Actions
        ...userActions(api),
        // Keyword to Topic Controller Actions
        ...KeywordTopicActions(api),
        // Topic Taxonomy Actions
        ...topicActions(api),
        // Domain Append Controller Actions
        ...domainAppendActions(api),
        // Release Controller Actions
        ...releaseActions(api)
    }
}
